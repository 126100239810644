import * as React from "react"
import {useState, useEffect} from "react"
import { Helmet } from "react-helmet"
import i18n from '../../components/i18n'
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import NavigationHelper from "../../helpers/navigation"
import Layout from "../../components/layout"
import JoinUsSingleBoxCard from "../../components/joinus_single_box_card"
import { JoinUsSingleContactForm } from "../../components/joinus_single_contact_form"
import SocialConfData from "../../conf/social.json"
import Seo from '../../components/seo'

// markup
const JobPage = (props) => {
  
  const [activeLanguage, setActiveLanguage] = useState()
  useEffect(() => { setActiveLanguage(i18n.language); }, [setActiveLanguage])

  const { t } = useTranslation(['main', 'forms']);
  const ExtContactForm = withTranslation('forms')(JoinUsSingleContactForm)

  // Navigation info
  const pageInfo = {
    key: "job",
    item_key: "j202203002",
    lang: i18n.language
  }

  // Job Offer related information
  const job_title = t('main:joinus_job-description_cloud-native-dev-joboffer-title_txt')
    , job_title_fragment_1 = t('main:joinus_job-description_cloud-native-dev-joboffer-title_p1')
    , job_title_fragment_2 = t('main:joinus_job-description_cloud-native-dev-joboffer-title_p2')
    , job_ref_number = "202203-002"
    , job_category = t('main:joinus_main_dev-category_title')
    , job_contract_type = t('main:joinus_job-description_unlimited-contract-type_txt')
    , job_start_date = t('main:joinus_job-description_start-asap_txt')
    , job_city_place = t('main:joinus_job-description_work-in-france_txt')
    , job_remote_policy = t('main:joinus_job-description_remote-friendly_txt')
    , job_required_diploma = t('main:joinus_job-description_diploma-master-required_txt')

  //For the social share buttons
  const title = `${t('main:joinus_meta_a1-recruits-prefix_title')}: ${t('main:joinus_job-description_cloud-native-dev-joboffer-title_txt')}`;
  const url = props.location.href;
  const twitterHandle = SocialConfData.ids.twitter;
  const tags = [
    t('main:joinus_j202203002_social_twitter_tag1_txt'),
    t('main:joinus_j202203002_social_twitter_tag2_txt'),
    t('main:joinus_j202203002_social_twitter_tag3_txt'),
    t('main:joinus_j202203002_social_twitter_tag4_txt')
  ];

  return (
    <Layout
      location={props.location}
      pageInfo={pageInfo}
    >
      <Helmet
      bodyAttributes={{
          class: 'rejoindre-page-detail'
      }}
      >
      </Helmet>

      <Seo
        description={t('main:joinus_j202203002_meta-seo-description_txt')}
        title={`${t('main:joinus_meta_a1-recruits-prefix_title' )}: ${job_title}`}
        keywords={[
          t('main:joinus_j202203002_meta-seo-keyword1_txt'),
          t('main:joinus_j202203002_meta-seo-keyword2_txt'),
          t('main:joinus_j202203002_meta-seo-keyword3_txt'),
          t('main:joinus_j202203002_meta-seo-keyword4_txt'),
          t('main:joinus_j202203002_meta-seo-keyword5_txt'),
          t('main:joinus_j202203002_meta-seo-keyword6_txt'),
        ]}
        url={props.location.href}
        image_url={`${props.location.origin}/a1_cloud_logo.png`}
      />

    {/* breadcumbs */}
    <div className="site-breadcumbs">
      <div className="container">
        <small>
          <a href={NavigationHelper.useGeneratePageURL(activeLanguage, 'home')} title={t('main:global_header_home_menu-item-seo-title_txt')}>
            A1:
          </a>{" "}
          // <a href={NavigationHelper.useGeneratePageURL(activeLanguage, 'join')} title={t('main:global_header_join_menu-item-seo-title_txt')}>{t('main:joinus_main_breadcrumb_title')}</a> / {job_title}
        </small>
      </div>
    </div>
    {/* end breadcumbs */}

  <div className="nosrej_head service_header">
    <div className="container">
      <div className="title_block">
        <h1 className="nos-service desktop_view">
          <span>
          {t('main:joinus_header_job-description_title')}<sub> #{job_ref_number}</sub>{" "}
          </span>{" "}
          {job_title}
        </h1>
        <h1 className="nos-service mobile_view">
          <span>
          {t('main:joinus_header_job-description_title')}<sub> #{job_ref_number}</sub>{" "}
          </span>{" "}
          {job_title}
        </h1>
      </div>
    </div>
  </div>
  {/* service single header end  */}
  {/* middle section  */}
  <div className="service_detail nosrej_service">
    <div className=" text-center desktop_view">
      <h2>
        {t('main:joinus_main_we-recruit-one_title')}
        <span style={{ color: "#29abe1" }}> {job_title}</span>
      </h2>
    </div>
    <div className="container">
      <div className="block_box">
        <div className="service-page-box-row first-box">
          <JoinUsSingleBoxCard
           category="dev"
           category_label={job_category}
           job_offer_label={t('main:joinus_main_job-opportunity_label')}
           job_name={job_title}
           job_name_fragment_1={job_title_fragment_1}
           job_name_fragment_2={job_title_fragment_2}
           job_contract_type={job_contract_type}
           job_start_date={job_start_date}
           job_city_place={job_city_place}
           job_remote_policy={job_remote_policy}
           job_required_diploma={job_required_diploma}
           share_label={t('main:global_main_share_label')}
           share_title={title}
           share_url={url}
           share_twitter_handle={twitterHandle}
           share_twitter_tags={tags}
           />
        </div>
      </div>
      <div className="middle_content">
        <div className="mobile_view">
          <h2>
            {t('main:joinus_main_we-recruit-one_title')}
            <span style={{ color: "#29abe1" }}> {job_title}</span>
          </h2>
        </div>
        <Trans i18nKey="main:joinus_j202203002_description_intro_html">
        <p>
          Dans le cadre du développement de projets internes, nous recherchons une perle rare capable de développer des solutions scalables, avec des architectures "modernes" type <i>micro-services</i> et/ou <i>serverless</i>.
        </p>
        <p>
          Un développeur type <i>Full-Stack</i> capable de travailler dans des environnements Cloud et/ou conteneurisés.
        </p>
        </Trans>

        <p>
        <Trans i18nKey="main:joinus_j202203002_description_frame_html">
          Il s'agit d'un poste en interne, pas en délégation chez un client, pour intégrer l'équipe qui développe et maintient les projets internes.
        </Trans>
        </p>
        <Trans i18nKey="main:joinus_j202203002_description_goals_html">
          <p>
            Les projets en question sont au stade de la preuve de concept et il est donc possible d'avoir un réel impact sur les choix techniques qui sont pris.
          </p>
          <p>
            Nous recherchons donc quelqu'un d'autonome et de polyvalent qui n'a pas peur de prendre des décisions ou des responsabilités sur le plan architectural et technique.
          </p>
        </Trans>

        <h4>{t('main:joinus_main_skills-section_title')}</h4>
            <ul>
              <li><b>.</b><Trans i18nKey="main:joinus_j202203002_description_skills_1_html">Python</Trans></li>
              <li><b>.</b><Trans i18nKey="main:joinus_j202203002_description_skills_2_html">GoLang</Trans></li>
              <li><b>.</b><Trans i18nKey="main:joinus_j202203002_description_skills_3_html">NodeJS</Trans></li>
              <li><b>.</b><Trans i18nKey="main:joinus_j202203002_description_skills_4_html">Typescript / Javascript</Trans></li>
              <li><b>.</b><Trans i18nKey="main:joinus_j202203002_description_skills_5_html">React / React Native (<i>nice to have</i>)</Trans></li>
              <li><b>.</b><Trans i18nKey="main:joinus_j202203002_description_skills_6_html">Kubernetes, Docker</Trans></li>
              <li><b>.</b><Trans i18nKey="main:joinus_j202203002_description_skills_7_html">Git, Gitflow, GitOps</Trans></li>
              <li><b>.</b><Trans i18nKey="main:joinus_j202203002_description_skills_8_html">Connaître le fonctionnement d'au moins un fournisseur majeur de Cloud public (<i>AWS, Azure, GCP par exemple</i>)</Trans></li>
              <li><b>.</b><Trans i18nKey="main:joinus_j202203002_description_skills_9_html">Firebase</Trans></li>
            </ul>

        <h4>{t('main:joinus_main_profile-section_title')}</h4>
        <Trans i18nKey="main:joinus_j202203002_description_profile_html">
          <p>
            Tu as une expérience significative dans des environnements comparables aux notres (avec les contraintes liées au Cloud, aux conteneurs et au déploiement continu).
          </p>
          <p>
            Tu sais travailler en équipe, apprécies le pair programming et tu comprends qu'il faudra automatiser les tests pour gagner en vélocité.
          </p>
          <p>
            La qualité du code, sa maintenabilité, sa sécurité et la scalabilité font partie de tes préoccupations quotidiennes.
          </p>
          <p>
            Côté langues, tu t'exprimes aisément, à l'écrit et à l'oral en français et en anglais.
          </p>
        </Trans>
        <h4>{t('main:joinus_main_interviews-section_title')}</h4>
        <p>{t('main:joinus_j202203002_description_interviews_steps_intro_txt')}</p>
        <ul>
          <li>
            <b>.</b><Trans i18nKey="main:joinus_j202203002_description_interviews_steps_1_html">Un échange technique à distance avec un des DevOps</Trans>
          </li>
          <li>
            <b>.</b><Trans i18nKey="main:joinus_j202203002_description_interviews_steps_2_html">Un échange avec au moins un des fondateurs d'A1 Cloud</Trans>
          </li>
          <li>
            <b>.</b><Trans i18nKey="main:joinus_j202203002_description_interviews_steps_3_html">Une proposition (<i>si tout se passe bien</i>)"</Trans>
          </li>
        </ul>
      </div>
      <div className="form_block">

        <ExtContactForm
        contact_source={`${pageInfo.item_key}: ${job_title}`}

        name_label={t('forms:home_contact_name_label' )}
        name_ph={t('forms:home_contact_name_placeholder' )}
        fname_label={t('forms:home_contact_firstname_label' )}
        fname_ph={t('forms:home_contact_firstname_placeholder' )}
        company_label={t('forms:home_contact_company_label' )}
        company_ph={t('forms:home_contact_company_placeholder' )}
        phone_label={t('forms:home_contact_phone_label' )}
        phone_ph={t('forms:home_contact_phone_placeholder' )}
        email_label={t('forms:home_contact_email_label' )}
        email_ph={t('forms:home_contact_email_placeholder' )}
        message_label={t('forms:home_contact_message_label' )}
        message_ph={t('forms:services_contact_message_placeholder' )}
        terms_msg_p1={t('forms:home_contact_terms_p1' )}
        terms_msg_p2={t('forms:home_contact_terms_p2' )}
        send_btn={t('forms:home_contact_send_btn')}

        notif_at_least_one_error_msg={t('forms:global_error_at_least_one_msg')}
        notif_sending_form_msg={t('forms:global_info_sending_form_msg')}
        success_message_sent_msg={t('forms:global_success_message_sent_msg')}
        notif_unknown_error_msg={t('forms:global_error_unknown_msg')}
        notif_email_validation_error_msg={t('forms:notif_email_validation_error_msg')}
        notif_lname_validation_error_msg={t('forms:notif_lname_validation_error_msg')}
        notif_fname_validation_error_msg={t('forms:notif_fname_validation_error_msg')}
        notif_number_validation_error_msg={t('forms:notif_number_validation_error_msg')}
        notif_message_validation_error_msg={t('forms:notif_message_validation_error_msg')}
      />
      </div>
    </div>
  </div>
  
  
  
  {/* main content end */}
    </Layout>
  )
}

export default JobPage
